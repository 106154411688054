import React from "react";
import { graphql } from "gatsby";
import EditorialContentComponent from "../../components/editorial-content";
import { useI18next } from "gatsby-plugin-react-i18next";
import PrivateRoute from "../../components/private-route";
export const query = graphql`
  query EditorialContentNews($Slug: String!, $language: String!) {
    newsContent: strapiEditorialContent(Slug: { eq: $Slug }) {
      Slug
      AlternateSlug
      Title
      Description
      therapeutical_area {
        Name
        Slug
        id
      }
      editorial_content_type {
        Name
        Slug
      }
      headerImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      Thumbnail {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContent
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      pageContentSeeMoreThumbs {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 276, height: 223)
          }
        }
      }
      pageContentDownloads {
        localFile {
          publicURL
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const EditorialContentNewsComponent = ({ data }) => {
  const { t } = useI18next();
  if (!data) return null;

  return (
    <EditorialContentComponent
      type="news"
      parentTitle={t("nav.news")}
      parentLink="/news"
      editorialContent={data.newsContent}
    />
  );
};

const EditorialContentNews = ({ data }) => {
  return <PrivateRoute component={EditorialContentNewsComponent} data={data} />;
};

export default EditorialContentNews;
